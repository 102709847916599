import React from 'react'
import { graphql, Link, PageProps } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import ParagraphsHolder from '../components/paragraphs-holder'

interface SinglePageProps extends PageProps {
    data: {
        datoCmsPage: {
            id: string;
            title: string;
            paragraphs: any[]; // TODO
        }
    },
    pageContext: {
        // TODO: Modeln
        posts: any[];
    }
}

const SinglePage: React.FC<SinglePageProps> = ({ data, path, pageContext }) => {
    return (
        <Layout>
            <SEO title={data.datoCmsPage.title} />
            <h1 className='mb-6 text-2xl text-center md:text-3xl'>{data.datoCmsPage.title}</h1>
            <ParagraphsHolder paragraphs={data.datoCmsPage.paragraphs}></ParagraphsHolder>
        </Layout>
    )
}

export default SinglePage;

export const query = graphql`
query AllBlogSinglePages($id: String = "") {
    datoCmsPage(id: { eq: $id }) {
        id
        title
        paragraphs {
            ... on DatoCmsText {
                id
                model {
                    apiKey
                }
                textNode {
                    childMarkdownRemark {
                        html
                    }
                }
            }
            ... on DatoCmsImage {
                id
                model {
                    apiKey
                }
                image {
                    fluid(maxWidth: 728) {
                        ...GatsbyDatoCmsFluid
                    }
                }
            }
        }
    }
}
`
